<template>
  <div class="flex flex-col items-center justify-center text-center w-full h-full bg-veturi-main text-white">
    <h1 class="font-bold text-4xl">Falha na conexão</h1>
    <p class="mt-4 text-xl">Não foi possível se conectar ao servidor.</p>
    <router-link to="/home">
      <p class="mt-4 text-3xl font-semibold">Clique aqui para tentar novamente.</p>
    </router-link>
  </div>
</template>

<script>
export default {

}
</script>
